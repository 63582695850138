import React from "react";
import { Modal, Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import FormikInputField from "../../Components/Formik/FormikInputField";
import AddIcon from "../../Components/Svgicons/AddIcon";
import styled from "styled-components";
import FormikAsyncSelect from "../../Components/Formik/FormikAsyncSelect";
import Ribbon from "../../Assets/Images/giftIcon.png";
import { USERTYPE, useWindowSize } from "../../Services/Utils/helper";
import FormikCardRadio from "../../Components/Formik/FormikCardRadio";
import Book from "../../Assets/Images/book.png";
import Cheque from "../../Assets/Images/check.png";
import { svenskaLs } from "../../Services/Utils/localStorage";

const DocumentPostingModal = ({
  show,
  onHide,
  loading,
  disableLoading,
  handleSubmit,
  validationSchema,
  user,
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useWindowSize();
  let userType = svenskaLs.getObject("userType");
  const options = [
    {
      value: "BOOK",
      label: `A Book <br/> Reward`,
      image: Book, // Replace with book image URL
    },
    {
      value: "CHEQUE",
      label: "A Cheque <br/> Reward",
      image: Cheque, // Replace with cheque image URL
    },
    {
      value: "BOTH",
      options: [
        {
          value: "book",
          label: `A Book <br/> Reward`,
          image: Book, // Replace with book image URL
        },
        {
          value: "cheque",
          label: "A Cheque <br/> Reward",
          image: Cheque, // Replace with cheque image URL
        },
      ],
    },
  ];

  return (
    <Modal show={show} centered onHide={onHide} size={"lg"}>
      <Modal.Body className="d-flex flex-column bg-body rounded p-3 p-md-5">
        <div className="d-flex flex-column align-items-center">
          <StyledText
            $fontSize="20px"
            $fontWeight={600}
            color={theme.secondary}
          >
            {t("common:requestNewDocument")}
          </StyledText>
          <StyledText $fontSize="14px" $fontWeight={400} color={theme.gray}>
            {t("common:selectDocumentDescription")}
          </StyledText>
        </div>
        <hr className="my-3" />
        <Formik
          enableReinitialize
          initialValues={{
            fileId: "",
            name: `${user?.firstName} ${user?.lastName}`,
            phoneNumber: user?.phoneNumber,
            email: user?.email,
            country: user?.country,
            county: user?.county,
            town: user?.town,
            streetAddress: user?.streetAddress,
            postalCode: user?.postalCode,
            gift: undefined,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <StyledContainer>
                <Row>
                  <div className="col-12">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="fileId"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("client:selectDocument")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="fileId"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikAsyncSelect
                      name="fileId"
                      id="fileId"
                      userId={user?.id}
                      type="sharedDocs"
                      isMulti={true}
                      placeholder={t("client:selectDocumenthere")}
                    />
                  </div>

                  <div className="mt-4 col-md-6 feature-pending">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="name"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:name")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name="name"
                      id="lastName"
                      placeholder={t("common:enterNameHere")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="phoneNumber"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textPhoneNumber")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="phoneNumber"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder={t("common:enterPhoneNo")}
                    />
                  </div>

                  <div className="mt-4 col-md-6 feature-pending">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="email"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textEmailAddress")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="email"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name="email"
                      id="email"
                      placeholder={t("common:enterEmail")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        $fontWeight={600}
                        $fontSize="14px"
                        htmlFor="country"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textCountry")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="country"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name="country"
                      id="country"
                      placeholder={t("common:enterCountry")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="county"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:county")}
                      </StyledText>
                      {/* <StyledText
                        as='label'
                        htmlFor='name'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText> */}
                    </div>
                    <FormikInputField
                      name="county"
                      id="county"
                      placeholder={t("common:enterCounty")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="town"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textTown")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-0"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name="town"
                      id="town"
                      placeholder={t("common:enterTown")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="streetAddress"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:streetAddress")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name="streetAddress"
                      id="streetAddress"
                      placeholder={t("common:enterStreetAdress")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="postalCode"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textPostalCode")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name="postalCode"
                      id="postalCode"
                      placeholder={t("common:enterPostalCode")}
                    />
                  </div>
                </Row>
              </StyledContainer>

              {userType === USERTYPE.ADMIN ? (
                <GiftContainer>
                  <GiftIconContainer>
                    <img src={Ribbon} alt="giftIcon" width={72} />
                  </GiftIconContainer>
                  <div className="d-flex flex-column align-items-center">
                    <StyledText
                      $fontSize={isSmallScreen ? "14px" : "16px"}
                      $fontWeight={600}
                      color={theme.secondary}
                    >
                      Do You Want To Attach Gift With This Request
                    </StyledText>
                    <StyledText
                      $fontSize="12px"
                      $fontWeight={400}
                      color={theme.gray}
                    >
                      Attach one of the gift below or you can attach both
                    </StyledText>
                  </div>
                  <div className="mt-3 d-flex align-items-center justify-content-center">
                    <FormikCardRadio id="gift" name="gift" options={options} />
                  </div>
                </GiftContainer>
              ) : null}

              <StyledButton
                icon={<AddIcon />}
                className="mt-5 py-2 text-white d-inline-flex w-100"
                type="submit"
                loading={loading}
                disabled={disableLoading}
              >
                {t("common:requestDocument")}
              </StyledButton>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const StyledContainer = styled.div`
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const GiftContainer = styled.div`
  position: relative;
  background-color: #f7f7f7;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  margin-top: 20px;
  padding: 25px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const GiftIconContainer = styled.div`
  position: absolute;
  left: -35px;
  top: -24px;
`;

export default DocumentPostingModal;
