import React from "react";
import Proptypes from "prop-types";
import { StyledRadio } from "./StyledRadio";
import { capitalizeFirstLetter } from "../../Services/Utils/helper";
import styled from "styled-components";
import StyledText from "./StyledText";
import theme from "../../Services/Utils/themes";
import { isArray } from "lodash";
import { VR } from "./CommonStyled";

const StyledCardRadioButton = ({
  value,
  onChange,
  label,
  disabled,
  type,
  hasNoLabel,
  size,
  name,
  option,
  img,
  ...props
}) => {
  const onChangeRadio = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <Container onClick={onChangeRadio}>
      <label
        htmlFor={`${label}+${name}`}
        className={`${
          props?.className ?? ""
        } d-flex align-items-center mb-0 me-2`}
        style={{ cursor: "pointer" }}
      >
        <StyledRadio value={value} size={14} {...props}>
          {value && <span className="dot" />}
        </StyledRadio>
        {/* {label && !hasNoLabel && (
          type === 'jobOffer' ? <span className={`ms-2 text-break`}>{label?.toUpperCase()}</span> : <span className={`ms-2 text-break`}>{capitalizeFirstLetter(label)}</span>
        )} */}
      </label>
      <input
        id={`${label}+${name}`}
        type="radio"
        style={{ display: "none" }}
        checked={value}
        disabled={disabled}
        // onChange={onChangeRadio}
      />

      <GiftContainer>
        {isArray(option?.options) ? (
          option?.options?.map((op, i) => (
            <>
              <div key={i}>
                <img src={op?.image} alt="img" width={34} height={46} />
              </div>
              <StyledText
                $fontWeight={600}
                $fontSize="12px"
                color={theme.secondary}
                dangerouslySetInnerHTML={{
                  __html: op.label,
                }}
              />
              {i !== option?.options?.length - 1 && <VR color={theme.primary}/>}
            </>

          ))
        ) : (
          <>
            <div>
              <img src={img} alt="img" width={34} height={46} />
            </div>
            <StyledText
              $fontWeight={600}
              $fontSize="12px"
              color={theme.secondary}
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
          </>
        )}
      </GiftContainer>
    </Container>
  );
};

StyledCardRadioButton.propTypes = {
  value: Proptypes.bool,
  onChange: Proptypes.func,
  label: Proptypes.string,
};

const Container = styled.div`
  display: flex;
  border: 1px dashed #d9a76c;
  height: 65px;
  border-radius: 5px;
  padding-left: 10px;
`;

const GiftContainer = styled.div`
  background-color: rgba(199, 123, 33, 0.2);
  height: 65px;
  display: flex;
  align-items: center;
  border-radius: 0 5px 5px 0;
  padding: 0 10px 0 10px;
  gap: 5px;
`;

export default StyledCardRadioButton;
