import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import MonitorIcon from "../../Components/Svgicons/MonitorIcon";
import MonitortabIcon from "../../Components/Svgicons/MonitortabIcon";
import QRCode from "react-qr-code";
import {
  useCreateUserMutation,
  useGetOrderRefMutation,
  useGetQrCodeDataMutation,
  useGetSignupQrCodeDataMutation,
} from "../../Services/loginApi/loginApiSlice";

import { useNavigate } from "react-router-dom";
import VerifiedIcon from "../../Components/Svgicons/VerifiedIcon";
import { svenskaLs } from "../../Services/Utils/localStorage";
import { setCredentials } from "../../Services/Auth/authSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import StyledLanguageSwitch from "../../Components/StyledComponents/StyledLanguageSwitch";
import Skeleton from "react-loading-skeleton";
import FormikInputField from "../../Components/Formik/FormikInputField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Carousel from "../../Components/Carousel/Carousel";
import {
  AuthContainer,
  CarouselContainer,
  QRContainer,
  StyledBgContainer,
  StyledCardContainer,
  StyledSelectOption,
} from "../../Components/StyledComponents/CommonStyled";
import { useWindowSize } from "../../Services/Utils/helper";
import svenskaLogo from "../../Assets/Images/svenskaLogo.png";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import { toast } from "react-toastify";
import { Row } from "react-bootstrap";

function AuthForm({ handleSubmit, path, QrData }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const [otherDevice, setOtherDevice] = useState(null);
  const [response, setResponse] = useState({});
  const [newResp, setNewResp] = useState({});
  const [verified, setVerified] = useState(false);
  const [status, setStatus] = useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const isSmallScreen = useWindowSize();
  const navigate = useNavigate();
  const [qrGenerating] = useGetQrCodeDataMutation();
  const [orderRefCollect] = useGetOrderRefMutation();
  const [signupQrGenerating] = useGetSignupQrCodeDataMutation();
  const [createUser, { isLoading: signupLoading }] = useCreateUserMutation();
  const dispatch = useDispatch();
  const handleQrCode = () => {
    QrData().then(({ data }) => {
      setResponse(data);
    });
    setOtherDevice(2);
  };

  useEffect(() => {
    if (
      otherDevice === 2 &&
      response?.orderRef &&
      !status &&
      !showDetails?.qrData
    ) {
      const qrCall = !path.includes("login")
        ? qrGenerating
        : signupQrGenerating;
      let dataStatus = false;

      const pollData = async () => {
        for (let seconds = 0; !dataStatus && seconds < 20; seconds++) {
          try {
            const { data } = await qrCall(response.orderRef);

            if (data?.status === "complete") {
              dataStatus = true;
            } else if (data?.hintCode === "userSign") {
              setVerified(true);
            } else if (
              seconds === 19 &&
              data?.hintCode === "outstandingTransaction"
            ) {
              setOtherDevice(null);
            }

            setNewResp(data);
          } catch (error) {
            // Handle any errors here
            console.error("Error polling data:", error);
          }

          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before the next iteration
        }
      };

      pollData();
    }
    // eslint-disable-next-line
  }, [response, otherDevice, status]);

  useEffect(() => {
    if (newResp?.status === "complete") {
      if (!path.includes("login")) {
        orderRefCollect(response?.orderRef)
          .then((resp) => {
            setStatus(true);
            if (resp?.data?.token) {
              dispatch(
                setCredentials({
                  token: { accessToken: resp?.data?.token },
                  account: resp?.data?.userInfo,
                  userType: resp?.data?.userInfo?.userType,
                })
              );
              svenskaLs.removeItem("orderRef");
              navigate("/dashboard");
            } else {
              if (resp?.error?.status === 404) {
                navigate("/login");
                toast.info(
                  <div>
                    {t("common:accountNotFound")}&nbsp;
                    <StyledText
                      onClick={() => navigate("/signup")}
                      className="fw-bold text-decoration-underline"
                    >
                      {t("common:signUp")}
                    </StyledText>
                    &nbsp;
                    {t("common:getStarted")}
                  </div>,
                  {
                    autoClose: 5000,
                  }
                );
              }
            }
          })
          .catch((e) => {
            navigate("/login");
          });
      } else {
        setShowDetails(newResp);
        // setOtherDevice(null);
      }
    }
    // eslint-disable-next-line
  }, [newResp?.status]);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        t("common:invalidEmail")
      )
      .required(t("common:emailRequired")),
    phone: Yup.string()
      .required(t("common:phoneRequired"))
      .matches(/^\d+$/, t("common:phoneRequiredDigit")),
    country: Yup.string().required(t("common:countryRequired")),
    // county: Yup.string().required(t("common:cityRequired")),
    town: Yup.string().required(t("common:townRequired")),
    streetAddress: Yup.string().required(t("common:streetAddressRequired")),
    postalCode: Yup.string().required(t("common:postalCodeRequired")),
  });

  const onSubmit = (values) => {
    const fName = showDetails?.userData?.name?.split(" ");
    const userData = {
      firstName: fName[0] ?? "",
      lastName: fName?.slice(1)?.join(" ") ?? "",
      email: values?.email,
      personalNumber: showDetails?.userData?.personalNumber,
      phoneNumber: values?.phone,
      ...values,
    };
    createUser(userData)
      .then(({ data }) => {
        if (data?.data?.token) {
          dispatch(
            setCredentials({
              token: { accessToken: data?.data?.token },
              account: data?.data?.userInfo,
              userType: data?.data?.userInfo?.userType,
            })
          );
          svenskaLs.removeItem("orderRef");
          svenskaLs.removeItem("userData");
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else {
          toast.error(t("common:alreadyExistErrorMessage"), {
            autoClose: 5000,
          });
          navigate("/login");
        }
      })
      .catch((e) => {
        navigate("/login");
      });
  };

  const AccountType = [
    {
      id: 1,
      title: t("common:BankIdOnThisDevice"),
      icon: <MonitorIcon />,
      action: () => {
        handleSubmit();
      },
    },
    {
      id: 2,
      title: t("common:BankIdOnOtherDevice"),
      icon: <MonitortabIcon />,
      action: () => {
        handleQrCode();
      },
    },
  ];

  const slides = [
    {
      id: 1,
      title: t("common:sliderHeading1"),
      content: t("common:sliderDescription1"),
    },
    {
      id: 2,
      title: t("common:sliderHeading2"),
      content: t("common:sliderDescription2"),
    },
    {
      id: 3,
      title: t("common:sliderHeading3"),
      content: t("common:sliderDescription3"),
    },
  ];

  return (
    <StyledBgContainer>
      <StyledCardContainer>
        <div className="d-block d-md-none w-100">
          <div className="d-flex  align-items-center justify-content-end">
            <div className="d-flex justify-content-center w-100">
              <img src={svenskaLogo} alt="svenskaLogo" width={191} />
            </div>

            <StyledLanguageSwitch />
          </div>
        </div>

        {showDetails?.qrData ? null : (
          <CarouselContainer>
            <Carousel slides={slides} />
          </CarouselContainer>
        )}
        <AuthContainer isSignUp={showDetails?.qrData}>
          <div className=" d-none d-md-block">
            <div className="d-flex justify-content-end w-100">
              <StyledLanguageSwitch />
            </div>
          </div>

          <QRContainer>
            <div className="d-none d-md-block">
              <img src={svenskaLogo} alt="svenskaLogo" width={251} />
            </div>
            {verified ? null : (
              <div className="mt-4 ms-2">
                <StyledText
                  as="h1"
                  $fontWeight={600}
                  $fontSize={isSmallScreen ? "18px" : "24px"}
                  color={theme.oxfordBlue}
                >
                  {otherDevice
                    ? !path.includes("login")
                      ? t("common:ScanQrCodeToLogin")
                      : t("common:ScanQrCodeToSignup")
                    : !path.includes("login")
                    ? t("common:LoginScreenTitle")
                    : t("common:SignupScreenTitle")}
                </StyledText>

                <StyledText
                  as="p"
                  $fontWeight={400}
                  $fontSize={isSmallScreen ? "14px" : "16px"}
                  color={theme.doveGray}
                  className=" d-none d-md-block"
                  // onClick={() => setVerified(true)}
                >
                  {otherDevice
                    ? t("common:ScanQrCodeDesc")
                    : !path.includes("login")
                    ? t("common:LoginScreenDesc")
                    : t("common:SignUpMobileScreenDesc")}
                </StyledText>

                <StyledText
                  as="p"
                  $fontWeight={500}
                  $fontSize={isSmallScreen ? "14px" : "16px"}
                  color={theme.gray}
                  className="d-block d-md-none mb-0"
                  onClick={() => setVerified(true)}
                >
                  {!path.includes("login")
                    ? t("common:LoginMobileScreenDesc")
                    : t("common:SignUpMobileScreenDesc")}
                </StyledText>
              </div>
            )}

            {!otherDevice ? (
              <StyledSelectOption className="mt-4 mb-1">
                <div>
                  {AccountType?.map((item, index) => (
                    <div
                      className={`${
                        item?.id === 1 ? "d-md-none" : "d-none d-md-block"
                      }`}
                    >
                      <div
                        className={` d-flex flex-column align-items-center border rounded py-4 px-5 mb-3 cursor-pointer  ${
                          activeTab === item?.id ? "border border-primary" : ""
                        }`}
                        key={index}
                        onMouseEnter={() => setActiveTab(item?.id)}
                        onMouseLeave={() => setActiveTab(null)}
                        onClick={item?.action}
                      >
                        {item?.icon}
                        <StyledText
                          as="label"
                          $fontWeight={600}
                          $fontSize="14px"
                          color={theme.gray}
                          className="text-center mt-3 cursor-pointer"
                        >
                          {item?.title}
                        </StyledText>
                      </div>
                    </div>
                  ))}
                </div>
              </StyledSelectOption>
            ) : showDetails?.qrData ? (
              <div className="mt-2">
                <StyledText
                  as="h1"
                  $fontWeight={500}
                  $fontSize="16px"
                  color={theme.oxfordBlue}
                  className="mb-3 mb-md-3"
                >
                  {t("common:verifyYourDetials")}
                </StyledText>
                <div className="position-relative border w-100 bg-bgBody rounded-1">
                  <div className=" p-3 d-flex flex-column gap-2">
                    <div>
                      <StyledText
                        $fontSize="15px"
                        $fontWeight={600}
                        color={theme.oxfordBlue}
                      >
                        {t("common:textFullName")} :
                      </StyledText>{" "}
                      <StyledText $fontSize="15px" color={theme.doveGray}>
                        {showDetails?.userData?.name}
                      </StyledText>
                    </div>
                    <div>
                      <StyledText
                        $fontSize="15px"
                        $fontWeight={600}
                        color={theme.oxfordBlue}
                      >
                        {t("common:textPersonalNoFull")} :
                      </StyledText>{" "}
                      <StyledText $fontSize="15px" color={theme.doveGray}>
                        {showDetails?.userData?.personalNumber}
                      </StyledText>
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    phone: "",
                    country: "",
                    county: "",
                    town: "",
                    streetAddress: "",
                    postalCode: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form className="w-100 mt-3">
                    <Row>
                      <div className="col-12 col-md-6">
                        <div className="d-flex align-items-baseline mb-1">
                          <StyledText
                            $fontSize="14px"
                            $fontWeight={500}
                            color={theme.oxfordBlue}
                          >
                            {t("common:textEmailAddress")}
                          </StyledText>
                          <StyledText
                            as="label"
                            htmlFor="country"
                            color={theme.danger}
                            className="mt-2"
                          >
                            *
                          </StyledText>
                        </div>
                        <FormikInputField
                          type="text"
                          label="Email"
                          name="email"
                          placeholder={t("common:enterEmail")}
                        />
                      </div>

                      <div className="col-12 col-md-6 ">
                        <div className="d-flex align-items-baseline mb-1">
                          <StyledText
                            $fontSize="14px"
                            $fontWeight={500}
                            color={theme.oxfordBlue}
                          >
                            {t("common:phone")}
                          </StyledText>
                          <StyledText
                            as="label"
                            htmlFor="country"
                            color={theme.danger}
                            className="mt-2"
                          >
                            *
                          </StyledText>
                        </div>
                        <FormikInputField
                          label="phone"
                          name="phone"
                          placeholder={t("common:enterPhoneNo")}
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-3">
                        <div className="d-flex align-items-baseline mb-1">
                          <StyledText
                            as="label"
                            $fontWeight={500}
                            $fontSize="14px"
                            htmlFor="country"
                            color={theme.headingTextColor}
                            className="ml-1"
                          >
                            {t("common:textCountry")}
                          </StyledText>
                          <StyledText
                            as="label"
                            htmlFor="country"
                            color={theme.danger}
                          >
                            *
                          </StyledText>
                        </div>
                        <FormikInputField
                          name="country"
                          id="country"
                          placeholder={t("common:enterCountry")}
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-3">
                        <div className="d-flex align-items-baseline mb-1">
                          <StyledText
                            as="label"
                            htmlFor="county"
                            $fontWeight={500}
                            $fontSize="14px"
                            color={theme.headingTextColor}
                            className="ml-1"
                          >
                            {t("common:county")}
                          </StyledText>
                          {/* <StyledText
                        as='label'
                        htmlFor='name'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText> */}
                        </div>
                        <FormikInputField
                          name="county"
                          id="county"
                          placeholder={t("common:enterCounty")}
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-3">
                        <div className="d-flex align-items-baseline mb-1">
                          <StyledText
                            as="label"
                            htmlFor="town"
                            $fontWeight={500}
                            $fontSize="14px"
                            color={theme.headingTextColor}
                            className="ml-1"
                          >
                            {t("common:textTown")}
                          </StyledText>
                          <StyledText
                            as="label"
                            htmlFor="name"
                            color={theme.danger}
                            className="mt-0"
                          >
                            *
                          </StyledText>
                        </div>
                        <FormikInputField
                          name="town"
                          id="town"
                          placeholder={t("common:enterTown")}
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-3">
                        <div className="d-flex align-items-baseline mb-1">
                          <StyledText
                            as="label"
                            htmlFor="streetAddress"
                            $fontWeight={500}
                            $fontSize="14px"
                            color={theme.headingTextColor}
                            className="ml-1"
                          >
                            {t("common:streetAddress")}
                          </StyledText>
                          <StyledText
                            as="label"
                            htmlFor="name"
                            color={theme.danger}
                          >
                            *
                          </StyledText>
                        </div>
                        <FormikInputField
                          name="streetAddress"
                          id="streetAddress"
                          placeholder={t("common:enterStreetAdress")}
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-3">
                        <div className="d-flex align-items-baseline mb-1">
                          <StyledText
                            as="label"
                            htmlFor="postalCode"
                            $fontWeight={500}
                            $fontSize="14px"
                            color={theme.headingTextColor}
                            className="ml-1"
                          >
                            {t("common:textPostalCode")}
                          </StyledText>
                          <StyledText
                            as="label"
                            htmlFor="name"
                            color={theme.danger}
                            className="mt-2"
                          >
                            *
                          </StyledText>
                        </div>
                        <FormikInputField
                          name="postalCode"
                          id="postalCode"
                          placeholder={t("common:enterPostalCode")}
                        />
                      </div>
                    </Row>

                    <div className=" d-flex align-items-center gap-2 text-center justify-content-center mt-4">
                      <StyledBtn
                        type="button"
                        onClick={() => navigate("/login")}
                        className="btn btn-light px-4"
                      >
                        {t("common:textBtnCancle")}
                      </StyledBtn>
                      <StyledButton
                        type="submit"
                        className="btn btn-primary text-white px-md-4"
                        loading={signupLoading}
                        disabled={signupLoading}
                      >
                        {t("client:AccessYourWorkplace")}
                      </StyledButton>
                    </div>
                  </Form>
                </Formik>
              </div>
            ) : verified ? (
              <div className="mt-5 d-flex align-items-center justify-content-center flex-column">
                <VerifiedIcon size="100" />
                <StyledText
                  as="h1"
                  $fontWeight={400}
                  $fontSize="18px"
                  lh="1.5"
                  color={theme.oxfordBlue}
                  className="text-center px-4 mt-5"
                >
                  {t("common:TypeYourSecurityCode")}
                </StyledText>
              </div>
            ) : (
              <div className="border rounded p-3 mt-3 mb-4 ms-2">
                {newResp?.qrData ? (
                  <QRCode value={newResp?.qrData} />
                ) : (
                  <Skeleton height={200} width={200} />
                )}
              </div>
            )}

            {!verified && !otherDevice && (
              <StyledText
                as="p"
                $fontWeight={500}
                $fontSize={"14px"}
                color={theme.oxfordBlue}
                className="text-center "
              >
                {!path.includes("login")
                  ? t("common:createAccountText")
                  : t("common:haveAccountText")}{" "}
                <StyledBold onClick={() => navigate(path)}>
                  {!path.includes("login")
                    ? t("common:createAccountAction")
                    : t("common:haveAccountAction")}
                </StyledBold>
              </StyledText>
            )}
          </QRContainer>
        </AuthContainer>
      </StyledCardContainer>
    </StyledBgContainer>
  );
}

const StyledBtn = styled.button`
  background-color: ${({ theme }) => theme.silverColor};
`;

const StyledBold = styled.span`
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
`;

const FormStyledContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 620px);
  padding-right: 10px;
  margin-top: 15px;
`;

export default AuthForm;
