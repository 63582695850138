import React, { useEffect } from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Row } from "react-bootstrap";
import * as Yup from "yup";
import FormikInputField from "../../Components/Formik/FormikInputField";
import { Form, Formik, useFormikContext } from "formik";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import FileIcon2 from "../../Components/Svgicons/FileIcon2";
import moment from "moment";
import { useEditUserProfileMutation } from "../../Services/DashboardApi/dashboardApiSlice";
import { useTranslation } from "react-i18next";
import { setCredentials } from "../../Services/Auth/authSlice";
import { useDispatch } from "react-redux";
import { svenskaLs } from "../../Services/Utils/localStorage";

const ForceValidation = () => {
  const { validateForm, submitForm } = useFormikContext();

  useEffect(() => {
    // Validate form on mount
    validateForm();

    // Optional: If you want to mark fields as touched to show errors immediately
    // This will trigger error display without submitting
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        // Force a pseudo-submit to trigger error display
        submitForm();
      }
    });
  }, [validateForm, submitForm]);

  return null;
};

function EditProfile({
  setEditProfile,
  user,
  refetchData,
  setActiveUploadIcon,
}) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [editProfile, { isLoading: ProfileLoadling }] =
    useEditUserProfileMutation();
  const handleSubmit = (values) => {
    delete values.registeredAt;
    delete values.personalNumber;

    editProfile(values).then((resp) => {
      const lsToken = svenskaLs.getObject("token");
      const lsUserType = svenskaLs.getObject("userType");
      dispatch(
        setCredentials({
          token: { accessToken: lsToken },
          userType: lsUserType,
          account: values,
        })
      );

      setEditProfile(false);
      refetchData();
      setActiveUploadIcon(false);
    });
  };

  const userProfileValidation = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),

    email: Yup.string().email("Invalid email").required("Required"),
    country: Yup.string().required("Required"),

    // county: Yup.string().required("Required"),

    town: Yup.string().required(t("common:townRequired")),
    streetAddress: Yup.string().required(t("common:streetAddressRequired")),

    postalCode: Yup.string().required("Required"),
    personalNumber: Yup.string().required("Required"),
  });

  let customUser = {
    ...user,
    registeredAt: moment(user?.registeredAt).format("DD/M/YYYY"),
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    registeredAt: moment(user?.registeredAt).format("DD/M/YYYY") || "",
    country: "",
    county: "",
    town: "",
    streetAddress: "",
    postalCode: "",
    personalNumber: "",
    ...customUser,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={userProfileValidation}
        onSubmit={handleSubmit}
        initialStatus={{ submitCount: 1 }}
      >
        {({ handleSubmit, isValid }) => (
          <Form>
            <ForceValidation />
            <div className="border rounded-3 p-4 mt-4">
              <StyledText
                $fontSize="18px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {t("textPersonalInfo")}
              </StyledText>
              <Row className="mt-4">
                <div className="col-12 col-md-6">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        $fontSize="14px"
                        $fontWeight={600}
                        color={theme.headingTextColor}
                      >
                        {t("textFirstName")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-0"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField name="firstName" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4 mt-md-0">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        $fontSize="14px"
                        $fontWeight={600}
                        color={theme.headingTextColor}
                      >
                        {t("textLastName")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-0"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField name="lastName" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        $fontSize="14px"
                        $fontWeight={600}
                        color={theme.headingTextColor}
                      >
                        {t("textEmailAddress")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-0"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField name="email" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4 feature-pending">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={600}
                      color={theme.gray}
                      className="mb-1"
                    >
                      {t("textRegistrationDate")}
                    </StyledText>
                    <FormikInputField name="registeredAt" />
                  </div>
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        $fontSize="14px"
                        $fontWeight={600}
                        color={theme.headingTextColor}
                      >
                        {t("textCountry")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-0"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                    placeholder={t("common:enterCountry")}
                    
                    name="country" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        $fontSize="14px"
                        $fontWeight={600}
                        color={theme.headingTextColor}
                      >
                        {t("common:county")}
                      </StyledText>
                    </div>
                    <FormikInputField 
                    placeholder={t("common:enterCounty")}
                    name="county" />
                  </div>
                </div>
                <div className="mt-4 col-md-6">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      as="label"
                      htmlFor="town"
                      $fontWeight={600}
                      $fontSize="14px"
                      color={theme.headingTextColor}
                      className="ml-1"
                    >
                      {t("common:textTown")}
                    </StyledText>
                    <StyledText
                      as="label"
                      htmlFor="name"
                      color={theme.danger}
                      className="mt-0"
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    name="town"
                    id="town"
                    placeholder={t("common:enterTown")}
                  />
                </div>

                <div className="mt-4 col-md-6">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      as="label"
                      htmlFor="streetAddress"
                      $fontWeight={600}
                      $fontSize="14px"
                      color={theme.headingTextColor}
                      className="ml-1"
                    >
                      {t("common:streetAddress")}
                    </StyledText>
                    <StyledText as="label" htmlFor="name" color={theme.danger}>
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    name="streetAddress"
                    id="streetAddress"
                    placeholder={t("common:enterStreetAdress")}
                  />
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        $fontSize="14px"
                        $fontWeight={600}
                        color={theme.headingTextColor}
                      >
                        {t("textPostalCode")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField 
                    placeholder={t("common:enterPostalCode")}
                    name="postalCode" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4 feature-pending">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={600}
                      color={theme.gray}
                      className="mb-1"
                    >
                      {t("textPersonalNo")}
                    </StyledText>
                    <FormikInputField name="personalNumber" />
                  </div>
                </div>
              </Row>
            </div>
            <StyledButton
              icon={<FileIcon2 />}
              className="text-white rounded-2 mt-3"
              type="submit"
              disabled={ProfileLoadling || !isValid}
              loading={ProfileLoadling}
            >
              {t("textBtnSaveProfile")}
            </StyledButton>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EditProfile;
