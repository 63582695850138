import React from "react";
import { ErrorMessage, FastField } from "formik";
import Proptypes from "prop-types";
import { formErrorMessage } from "./FormErrorMessage";
import StyledCardRadioButton from "../StyledComponents/StyledCardRadioButton";

const FormikCardRadio = ({ name, options, disabled }) => {
  return (
    <FastField name={name}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <>
          <div className="d-flex align-items-center flex-wrap py-1 py-lg-2 gap-3">
            {options?.map((option, index) => (

              <StyledCardRadioButton
                label={option.label}
                option={option}
                id={`${name}-${index}-${option.value}`}
                value={value === option?.value}
                img={option?.image}
                disabled={disabled}
                onChange={() => {
                  setFieldValue(name, option.value);
                }}
                key={`radio-${name}-${index}-${option.value}`}
                name={name}
              />
            ))}
          </div>
          <ErrorMessage name={name} render={formErrorMessage} />
        </>
      )}
    </FastField>
  );
};
FormikCardRadio.propTypes = {
  name: Proptypes.string.isRequired,
  options: Proptypes.array.isRequired,
};

export default React.memo(FormikCardRadio);
